import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components";
import { Products } from "../components";
// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"
import { 
  Jumbo, 
  //Layout,
  Image,
  SEO
} from "../components";

export const query = graphql`
{
  allSite {
    edges {
      node {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  }

  allStripeSku {
    edges {
      node {
        id
        price
        product {
          id
          name
          metadata {
            img
            description
            wear
            color
          }
        }
      }
    }
  }
}
`;
const Button = styled.button`
  width:8rem;
  background-color:#98ca3f;
  border:none;
  border-radius:10px;
  color:${props=> props.color};
  &:hover{
    transform: scale(1.1);
  }
`

const IndexPage = ({ data }) => {
  
  console.log('data',data)
  return (
    <>
      <SEO title="Home"  />
      <Jumbo metaData={data.allSite.edges[0].node.siteMetadata}/>
      <Products products={ data.allStripeSku.edges } />
      <Button color='gray' >Comprar</Button>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link>
      <br />
      <Link to="/gracias/">Go to Gracias</Link>
      <br />
      <Link to="/cancelar/">Go to Cancelar</Link>
    </>
  )
};

export default IndexPage
